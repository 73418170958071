<template>
  <b-modal id="batch-modal" hide-header hide-footer centered size="xl" @show="clearForm">
    <div class="modal-header">
      <div>
        <p class="modal-title">
          Lote Nº {{ tissBatch.number }}
        </p>
      </div>
      <div>
        <Close class="icon" @click="close" />
      </div>
    </div>
    <div class="list-area">
      <b-row class="filters-container">
        <b-col cols="3">
          <label for="">Paciente</label>
          <div class="search-container">
            <Search class="icon" />
            <b-form-input
              type="text"
              v-model="filters.patient"
              debounce="500"
              placeholder="Buscar paciente"
            />
          </div>
        </b-col>

        <b-col cols="3">
          <label for="guideNumber">Nº da guia</label>
          <b-form-input
            id="guideNumber"
            type="text"
            v-model="filters.guideNumber"
            placeholder="Pesquisar"
            debounce="500"
          />
        </b-col>

        <b-col cols="3">
          <label for="conferred">Guia conferida</label>
          <div class="multiselect">
            <multiselect
              id="conferred"
              :option-height="40"
              label="label"
              :searchable="false"
              :close-on-select="true"
              :showLabels="false"
              placeholder="Selecionar"
              class="with-border"
              :value="conferred"
              :options="conditions"
              @select="changeCondition"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
            </multiselect>
          </div>
        </b-col>
      </b-row>

      <b-row class="px-2">
        <b-col cols="12">
          <b-card v-if="Object.values(tissGuides)?.length">
            <b-table-simple striped id="guides-table">
              <thead>
                <tr class="tr-header">
                  <th title="Data">Data</th>
                  <th title="Paciente/Guia">Paciente/Guia</th>
                  <th title="Nº da carteira">Nº da carteira</th>
                  <th title="Plano de saúde" >Plano de saúde</th>
                  <th title="Tipo de guia">Tipo de guia</th>
                  <th title="Valor">Valor</th>
                  <th title="Arquivos">Arquivos</th>
                  <th title="Conferido">Conferido</th>
                  <th></th>
                </tr>
              </thead>

              <tbody
                v-for="(guide, index) in tissGuides"
                :key="index"
              >
                <tr>
                  <td :title="moment(guide.created_at).format('DD/MM/YYYY') ?? '-'">
                    {{ moment(guide.created_at).format('DD/MM/YYYY') ?? '-' }}
                  </td>
                  <td :title="guide?.patient?.name + ' / Nº ' + guide.guide.guide_number">
                    <span>{{guide?.patient?.name}} </span>
                    <br />
                    <span>{{'Nº '+ guide.guide.guide_number}}</span>
                  </td>
                  <td :title="guide?.invoicing_tiss_setting?.clinic_health_plan?.health_plan?.fantasy_name ?? '-'">
                    {{ guide?.invoicing_tiss_setting?.clinic_health_plan?.health_plan?.fantasy_name ?? '-' }}
                  </td>
                  <td :title="getTypes(guide.guide_type)">
                    {{ getTypes(guide.guide_type) }}
                  </td>
                  <td :title="parseNumberToMoney(guide.guide.procedures_sum_total_value)">
                    {{ parseNumberToMoney(guide.guide.procedures_sum_total_value) }}
                  </td>
                  <td :title="guide.files_count > 0 ? 'Sim' : 'Não'">
                    {{ guide.files_count > 0 ? 'Sim' : 'Não' }}
                  </td>
                  <td>
                    <label class="checkbox ml-2 pb-3">
                      <input
                        type="checkbox"
                        v-model="guide.conferred"
                        @change="changeCheck(guide)"
                      />
                      <span class="checkmark">
                        <Check />
                      </span>
                    </label>
                  </td>
                  <td>
                    
                    <div class="actions">

                      <b-button v-b-toggle="`collapse-${index}-${guide.guide.id}`" class="toggle-btn"
                        :disabled="!guide?.guide?.procedures?.length">
                        <ChevronDown class="icon" />
                      </b-button>
                      <div class="options">
                        <MoreVertical class="more-icon" />
                        <div class="menu">
                          <b-button
                            variant="link"
                            @click="getGuide(guide.id)">
                            Ver detalhes
                          </b-button>
                          <!--<b-button variant="link" @click="openHistoric(guide.id)">
                            Histórico de ações
                          </b-button>-->
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="collapsable-row">
                  <td colspan="9">
                    <b-collapse :id="`collapse-${index}-${guide.guide.id}`" class="collapse">
                      <b-table-simple class="collapse-table">
                        <thead>
                          <tr>
                            <th scope="col">Atualização</th>
                            <th scope="col">Cód. Item</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Valor do proced.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(procedure, index) in guide.guide.procedures" :key="index">
                            <td>{{ moment(procedure?.updated_date).format('DD/MM/YYYY') }}</td>
                            <td>{{ procedure?.item?.code }}</td>
                            <td>{{ procedure?.item.name }}</td>
                            <td>{{ parseNumberToMoney(procedure?.total_value) }}</td>
                          </tr>
                        </tbody>
                      </b-table-simple>
                    </b-collapse>
                  </td>
                </tr>
              </tbody>
            </b-table-simple>

            <div class="d-flex justify-content-end mt-3">
              <b-pagination
                size="sm"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
              />
            </div>
          </b-card>

          <b-card v-else>
            <NoFilterResults message="Os filtros aplicados não geraram nenhum resultado." />
          </b-card>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-3"
          variant="primary"
          @click="close"
        >
          Concluir
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'BatchModal',
  props: {
    tissBatch: Object,
    getTypes: Function,
    openHistoric: Function,
    getGuide: Function
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    Check: () => import('@/assets/icons/check.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      conferred: '',
      tissGuides: [],
      filters: {
        patient: '',
        guideNumber: '',
        walletNumber: '',
        conferred: ''
      },
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      conditions: [
        {
          label: 'Selecionar',
          value: ''
        },
        {
          label: 'Sim',
          value: true
        },
        {
          label: 'Não',
          value: false
        }
      ]
    }
  },
  methods: {
    parseNumberToMoney,
    changeCondition(value) {
      this.conferred = value
      this.filters.conferred = value.value
    },
    async getTissGuides() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getSusGuideByBatchId(
          this.tissBatch.id,
          this.currentPage,
          this.filters
        )
        const data = response.data.guides
        this.tissGuides = data
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.totalItems = data.total
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    clearForm() {
      this.conferred = null
      for (const field in this.filters) {
        this.filters[field] = null
      }
    },
    close() {
      this.clearForm()
      this.$bvModal.hide('batch-modal')
    },
    async changeCheck(guide){
      try {
        await this.api.updateNewSusGuide(guide.id, {
          conferred: guide.conferred,
          clinic_id: this.clinic.id,
          guide_type: this.getTypes(guide.guide_type)
        })

        if (guide.conferred) {
          this.$toast.success('Guia conferida com sucesso!')
        } else {
          this.$toast.success('Guia desmarcada com sucesso!')
        }
      } catch (error) {
        this.$toast.error(error.message)
      }

    }
  },
  watch: {
    tissBatch: {
      async handler(value) {
        if (Object.keys(value).length) {
          this.rows = []
          await this.getTissGuides()
        }
      },
      deep: true,
      immediate: true
    },
    currentPage: {
      handler: async function () {
        await this.getTissGuides()
      }
    },
    filters: {
      handler: async function () {
        await this.getTissGuides()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#batch-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .list-area {
        .info-message-box {
          background-color: #e6f9ff;
          border-radius: 8px;
          padding: 16px 26px;
          display: flex;
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          align-items: center;
          margin-right: 24px;
          width: 100%;

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 25px;
            stroke: #0088b3;
          }
        }

        .card {
          border: none;
        }
      }

      .filters-container {
        margin: 20px 0;
        margin-left: 2%;
        margin-right: 2%;
        display: flex;

        label {
          font-weight: 700;
        }

        .search-container {
          display: flex;
          flex-direction: row;
          position: relative;

          .icon {
            position: absolute;
            width: 24px;
            height: 24px;
            stroke: var(--neutral-500);
            height: 100%;
            margin-left: 10px;
          }

          input {
            padding-left: 40px !important;
          }
        }
      }

      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }

  #guides-table {
    width: 100%;
    // width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    table-layout: fixed;
    margin: 5px 0;

    thead {
      font-weight: 600;
      color: var(--dark-blue);

      tr {
        background-color: var(--neutral-100);
      }
    }

    tbody {
      color: var(--type-active);
      font-weight: 600;

    }

    .spacing {
      height: 120px;
    }

    td {
      max-width: 350px !important;
    }

    td,
    th {
      @media (max-width: 1330px) {
        font-size: 12px;
      }

      .situation-tag {
        width: 100%;
        border-radius: 16px;
        padding: 5px 10px;
        background-color: var(--neutral-300);
        display: inline-block;
        text-align: center;
      }

      .text-tag {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
      }

      .toggle-btn {
        background: var(--blue-100);
        border: none;
        padding: 5px !important;

        .icon {
          width: 25px;
          transition: all 500ms;
        }
      }

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .multi-content {
        display: flex;
        align-items: center;

        .multi-content-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .text-container {
            max-width: 10ch !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .popover-element-container {
          visibility: hidden !important;
          position: relative;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid var(--neutral-300);
          background-color: var(--neutral-000);
          // margin-top: 20px;
        }
      }

      .multi-content:hover {
        .popover-element-container {
          visibility: visible !important;
          p {
            max-width: 20ch !important;
          }
        }
      }

      .checkbox {
        margin-left: 25px !important;
      }

      .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .options {
        cursor: pointer;
        width: 24px;
        height: 28px;
        position: relative;
        display: inline-block;

        .more-icon {
          width: 24px;
          height: 24px;
          stroke: var(--neutral-500);
          transition: all 0.5s;
          cursor: pointer;
        }

        .menu {
          z-index: 10;
          min-width: 180px;
          position: absolute;
          top: 28px;
          right: 0;
          background: var(--neutral-000);
          border: 1px solid var(--neutral-200);
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
            0px 4px 10px -8px rgba(12, 29, 89, 0.2);
          border-radius: 8px;
          padding: 0;
          opacity: 0;
          transition: all 0.5s;
          flex-direction: column;
          align-items: start;
          display: none;

          .mtb-12 {
            margin: 12px 0;
          }

          .btn {
            font-weight: 600;
            width: 100%;

            &.remove {
              color: var(--states-error);
            }

            &.btn-link {
              text-align: left;
              padding: 12px 18px !important;
            }
          }
        }

        &:hover {
          .more-icon {
            transform: rotate(90deg);
          }

          .menu {
            opacity: 1;
            display: flex;
            z-index: 10;
          }
        }
      }
    }

    .hide-btn {
      color: var(--blue-700);
      text-decoration: underline;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    td:last-child {
      overflow: visible;
    }

    .collapsable-row {
      td {
        padding: 0;
        border-top: none;
        border-bottom: 0.5px dotted var(--neutral-300);
      }

      .collapse-table {
        width: 100%;
        margin: 0 auto 20px auto;

        thead {
          tr {
            border-radius: 8px !important;
            background-color: var(--neutral-200);

            th {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
            }

            th:nth-child(1) {
              border-top-left-radius: 8px;
            }

            th:last-child {
              border-top-right-radius: 8px !important;
            }
          }
        }

        tbody {
          border: 1px dashed var(--neutral-200);

          tr {
            td {
              padding: 15px 5px;
            }

            td:nth-child(4) {
              max-width: 35ch;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.trash:hover {
        stroke: var(--states-error);
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: var(--neutral-000);
    border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 100px;
    border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--neutral-200);
  }
}
</style>
